import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineShareAlt } from "react-icons/ai";
import { MdPlaylistAdd } from "react-icons/md";
import { abbreviateNumber } from "js-abbreviation-number";
import { fetchDataFromApi } from "../utils/rapidApi";
import { Context } from "../context/contextApi";
import SuggestionVideoCard from "../components/SuggestionVideoCard";
import CommentCard from "../components/CommentCard";
import moment from "moment/moment";
import Moment from "react-moment";

const VideoDetails = () => {
  const [video, setVideo] = useState();
  const [relatedVideo, setRelatedVideo] = useState();
  const [comments, setComments] = useState();
  const { id } = useParams();
  const { setLoading } = useContext(Context);

  useEffect(() => {
    //document.getElementById("root").classList.add("custom-h");
    fetchVideoDetails();
    fetchRelatedVideos();
    fetchComments();
  }, [id]);

  const fetchVideoDetails = () => {
    setLoading(true);
    fetchDataFromApi(`video/details/?id=${id}`).then((res) => {
      // console.log("(VideoDetails.jsx) Video Details:");
      // console.log(res);
      setVideo(res);
      setLoading(false);
    });
  };

  const fetchRelatedVideos = () => {
    setLoading(true);
    fetchDataFromApi(`video/related-contents/?id=${id}`).then((res) => {
      // console.log("(VideoDetails.jsx) Related Videos:");
      // console.log(res);
      setRelatedVideo(res);
      setLoading(false);
    });
  };

  const fetchComments = () => {
    setLoading(true);
    fetchDataFromApi(`video/comments/?video_id=${id}`).then((res) => {
      // console.log("(VideoDetails.jsx) Comments:");
      // console.log(res);
      setComments(res);
      setLoading(false);
    });
  };

  const handlePublishedDate = (date) => {
    return date?.split("Z").filter(Boolean)?.[0];
  };

  return (
    <div className="flex flex-col lg:flex-row pl-5 pr-3 md:pl-8 md:pr-3 py-5 gap-6 overflow-y-scroll">
      {/** for left section video player */}
      <div className="flex grow  flex-col lg:w-[calc(68%)]">
        <div className="text-[20px] font-semibold">
          <div className=" aspect-video">
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${id}`}
              controls
              width="100%"
              height="100%"
            />
          </div>
          <span className="mt-2">{video?.title}</span>
          {/* Apple moving production from China to India remains 'vital,' says
          WSJ's Joanna Stern */}
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-3 ">
          {/** for author left section */}
          <div className="flex flex-row items-center gap-4 ">
            <div className="flex items-center justify-center h-11 w-11 rounded-full overflow-hidden">
              <img
                src={video?.author?.avatar[0]?.url}
                // src="https://xsgames.co/randomusers/assets/avatars/female/8.jpg"
                className="h-full w-full object-cover"
                alt=""
              />
            </div>
            <div className="flex-col flex items-start ">
              <div className="flex items-center text-[17px] font-semibold ">
                {/* CNBC Television */}

                <span className="truncate">{video?.author?.title}</span>
                {video?.author?.badges[0]?.type === "VERIFIED_CHANNEL" ||
                video?.author?.badges[0]?.type === "OFFICIAL_ARTIST_CHANNEL" ? (
                  <BsFillCheckCircleFill className="text-[14px] ml-2 opacity-70" />
                ) : (
                  ""
                )}
                {/* 
                {video?.author?.badges[0]?.type === "VERIFIED_CHANNEL" && (
                  <BsFillCheckCircleFill className="text-[14px] ml-2 opacity-70" />
                )} */}
              </div>
              <span className="text-sm">
                {video?.author?.stats?.subscribersText}
              </span>
            </div>
            <div className="rounded-3xl h-9 w-[110px] ml-3 flex items-center justify-center text-white font-semibold bg-black">
              Subscribe
            </div>
          </div>
          {/** for author right section */}
          <div className="flex flex-row items-center gap-3 mt-4 md:mt-0">
            <div className="rounded-3xl h-9 w-[115px]  flex items-center justify-between font-semibold bg-[#f0f0f0] px-3">
              {/* {abbreviateNumber(video?.stats?.likes, 0)} */}
              <div className="flex items-center gap-2 ">
                <AiOutlineLike className="w-5 h-5" />
                <span>994</span>
              </div>
              <hr className=" w-[1px] h-5 bg-[#d3d3d3] " />
              <div className="-scale-x-100">
                <AiOutlineDislike className="w-5 h-5" />
              </div>
            </div>
            <div className="rounded-3xl h-9 w-[100px]  flex items-center justify-center font-semibold bg-[#f0f0f0]">
              <div className="flex items-center gap-2 ">
                <AiOutlineShareAlt className="w-5 h-5" />
                <span>Share</span>
              </div>
            </div>
            <div className="rounded-3xl h-9 w-[110px] flex md:hidden xl:flex justify-center items-center font-semibold bg-[#f0f0f0]">
              <div className="flex items-center gap-2 ">
                <MdPlaylistAdd className="w-5 h-5" />
                <span>Save</span>
              </div>
            </div>
            <div className="rounded-full h-9 w-9 gap-1 flex items-center justify-center font-semibold bg-[#f0f0f0]">
              <span className="w-1 h-1 bg-black bg-opacity-75 rounded-full"></span>
              <span className="w-1 h-1 bg-black bg-opacity-75 rounded-full"></span>
              <span className="w-1 h-1 bg-black bg-opacity-75 rounded-full"></span>
            </div>
          </div>
        </div>
        {/** for video description */}
        <div className="px-3 py-3 mt-4 bg-[#f0f0f0] rounded-lg text-[15px]">
          <div className="font-semibold">
            {abbreviateNumber(video?.stats?.views, 0)} views &nbsp;&nbsp;
            <Moment fromNow>
              {handlePublishedDate(video?.date?.published)}
            </Moment>
          </div>
          <p className="line-clamp-3">
            {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laudantium
            quae aliquid veritatis quos aspernatur maxime placeat animi
            similique perspiciatis, obcaecati ratione, iste explicabo delectus
            neque labore accusantium tempora? Saepe, natus? */}
            {video?.description}
          </p>
        </div>
        {/* for comment section */}
        <div className=" flex-col pt-8 pb-32 gap-6 hidden lg:flex">
          <div className="text-lg">
            {abbreviateNumber(video?.stats?.comments, 0)} Comments
          </div>

          {comments?.comments?.map((item, index) => {
            return <CommentCard key={index} comments={item} />;
          })}
        </div>
      </div>

      {/** for right section related videos */}
      <div className="flex flex-col grow lg:w-[calc(32%)] gap-2">
        {relatedVideo?.contents?.map((item, index) => {
          if (item.type !== "video") return false;
          return <SuggestionVideoCard key={index} video={item?.video} />;
        })}
      </div>
    </div>
  );
};

export default VideoDetails;
