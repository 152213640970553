import axios from "axios";

/** for ver 1.0 */
const BASE_URL = "https://youtube138.p.rapidapi.com";

/**for */
//const BASE_URL = "https://youtube138.p.rapidapi.com/v1";

const options = {
  //params: { hl: "en", gl: "asia" },
  headers: {
    "X-RapidAPI-Key": process.env.REACT_APP_YOUTUBE_API_KEY,
    //"X-RapidAPI-Key": "test",
    "X-RapidAPI-Host": "youtube138.p.rapidapi.com",
  },
};

export const fetchDataFromApi = async (url) => {
  const { data } = await axios.get(`${BASE_URL}/${url}`, options);
  return data;
};
