import React, { Component } from "react";
import { abbreviateNumber } from "js-abbreviation-number";
import { Link } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import VideoLength from "../shared/VideoLength";

const SuggestionVideoCard = ({ video }) => {
  return (
    <Link to={`/video/details/${video?.videoId}`}>
      <div className="flex flex-row  gap-3">
        <div className="relative h-[94px] w-[168px] rounded-xl overflow-hidden">
          <img
            src={video?.thumbnails?.[0].url}
            // src="https://i.ytimg.com/vi/7F0jE1UAdt8/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLAiXXYduI4PG4AhyvsO-DEj1OrvXw"
            alt=""
            className="h-full w-full object-cover"
          />
          {video?.lengthSeconds && <VideoLength time={video?.lengthSeconds} />}
        </div>
        <div className="flex flex-col h-[94px] w-[calc(100%-168px)]">
          <span className="text-[15px] font-semibold line-clamp-2 leading-snug tracking-tight">
            {video?.title}
            {/* Leaked Pentagon Docs Show U.S. & U.K. Special Forces Already in
            Ukraine as War Heads to Stalemate */}
          </span>
          <span className="flex items-center gap-2 text-[14px] line-clamp-1 pt-1 text-black/80 tracking-tight truncate">
            {video?.author?.title}
            {video?.author?.badges[0]?.type === "VERIFIED_CHANNEL" && (
              <BsFillCheckCircleFill className="text-[#606060]" />
            )}
          </span>
          <span className="flex items-center text-[14px]  text-black/80 line-clamp-1 tracking-tight truncate">
            {`${abbreviateNumber(video?.stats?.views, 1)} views`}

            <div className="h-[4px] w-[4px] bg-black/70 rounded-full mx-2 " />

            {video?.publishedTimeText}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default SuggestionVideoCard;
