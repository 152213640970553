import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineShareAlt } from "react-icons/ai";
import { MdPlaylistAdd } from "react-icons/md";
import { abbreviateNumber } from "js-abbreviation-number";
import { fetchDataFromApi } from "../utils/rapidApi";
import { Context } from "../context/contextApi";
import SuggestionVideoCard from "../components/SuggestionVideoCard";
import moment from "moment/moment";
import Moment from "react-moment";

const CommentCard = ({ comments }) => {
  // console.log("(CoomentCard) props.content:");
  // console.log(comments.content);

  const handlePublishedDate = (date) => {
    return date?.split("Z").filter(Boolean)?.[0];
  };

  return (
    <div className="flex flex-row gap-4">
      <div className="flex h-10 w-10 overflow-hidden rounded-full cursor-pointer">
        <img src={comments?.author?.avatar} alt="" />
      </div>
      <div className=" w-full mt-0">
        <div className="text-sm ">
          <span className="font-semibold">@{comments?.author?.title}</span>
          <span>
            &nbsp;&nbsp;
            <Moment fromNow>
              {handlePublishedDate(comments?.date?.published)}
            </Moment>
          </span>
        </div>
        <span className="line-clamp-4 leading-snug text-sm">
          {comments?.content}
        </span>
        <div className="flex flex-row gap-3 items-center mt-3">
          <AiOutlineLike className="w-5 h-5" />
          <span className="text-sm">{comments.stats.likes}</span>
          <div className="-scale-x-100">
            <AiOutlineDislike className="w-5 h-5" />
          </div>
          <span className="text-sm">Reply</span>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
