import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { fetchDataFromApi } from "../utils/rapidApi";
import { Context } from "../context/contextApi";
import LeftNav from "../components/LeftNav";
import SearchResultVideoCard from "../components/SearchResultVideoCard";
import VideoCard from "../components/VideoCard";

const SearchResults = () => {
  const [result, setResult] = useState([]);
  const { searchParamQuery } = useParams();
  const { loading, setLoading } = useContext(Context);

  useEffect(() => {
    fetchSearchResult();
  }, [searchParamQuery]);

  const fetchSearchResult = () => {
    setLoading(true);
    fetchDataFromApi(`search/?q=${searchParamQuery}`).then(
      ({ contents }) => {
        console.log("(SearchResults.jsx) Search Results:");
        console.log(contents);
        setResult(contents);
        setLoading(false);
      }
    );
  };

  return (
    <div className="flex flex-row h-[calc(100%-56px)]">
      <LeftNav />
      <div className="flex flex-col gap-5 grow w-[calc(100%-240px)] h-full overflow-y-auto px-7  my-4">
        {!loading &&
          result.map((item, index) => {
            if (item.type !== "video") return false;
            return <SearchResultVideoCard key={index} video={item?.video} />;
          })}
      </div>
    </div>
  );
};

export default SearchResults;
