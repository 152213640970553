import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ytLogo from "../images/yt-logo.png";
import ytLogoLight from "../images/yt-logo-light.png";
import ytLogoMobile from "../images/yt-logo-mobile.png";
import { SlMenu } from "react-icons/sl";
import { IoIosSearch } from "react-icons/io";
import { RiVideoAddLine } from "react-icons/ri";
import { FiBell } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import { Context } from "../context/contextApi";
import Loader from "../shared/Loader";

const Header = () => {
  const [searchQuery, setSearchQuery] = useState();
  const { loading, mobileMenu, setMobileMenu } = useContext(Context);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageName = pathname?.split("/")?.filter(Boolean)?.[1];

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && searchQuery?.length > 0) {
      navigate(`/searchResults/${searchQuery}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchQuery?.length > 0) {
      navigate(`/searchResults/${searchQuery}`);
    }
  };

  const mobileMenuToggle = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleQuery = (param) => {
    if (param === "details") return "";
    else return param;
  };

  return (
    <div className="sticky top-0 left-0 right-0 z-10 flex flex-row items-center justify-between h-14 pl-2 md:pl-5 pr-5 md:pr-9 bg-white pt-2 pb-2">
      {loading && <Loader />}

      {/** mobile menu & icons left side */}
      <div className="flex h-5 items-center">
        {/** mobile menu */}
        {
          <div
            className="flex mr-4 cursor-pointer items-center justify-center h-10 w-10 rounded-full hover:bg-[#f0f0f0]"
            onClick={mobileMenuToggle}
          >
            {mobileMenu ? (
              <CgClose className="text-[#303030] text-lg" />
            ) : (
              <SlMenu className="text-[#303030] text-lg" />
            )}
          </div>
        }
        {/** icon & text */}
        <Link to="/" className="flex h-5 items-center">
          <img className="h-full " src={ytLogoLight} alt="YouTube" />
        </Link>
      </div>

      {/** search bar */}
      <form onSubmit={handleSubmit}>
        <div className="group items-center hidden  md:flex">
          <div className="flex h-8 md:h-10 md:ml-10 md:pl-5 border border-[#d3d3d3] rounded-l-3xl group-focus-within:border-blue-500 md:group-focus-within:ml-5 md:group-focus-within:pl-0">
            <div className="w-10 items-center justify-center hidden group-focus-within:md:flex">
              <IoIosSearch className="text-[#303030] text-xl" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="bg-transparent outline-none pr-5 pl-5 md:pl-0 w-44 md:group-focus-within:pl-0 md:w-64 lg:w-[500px]"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={handleEnterKeyPress}
              value={
                searchQuery === undefined
                  ? handleQuery(pageName?.replace(/%20/g, " "))
                  : searchQuery
              }
            />
          </div>
          <button
            type="submit"
            className="w-[40px] bg-[#f8f8f8] hover:bg-[#f0f0f0] md:w-[60px] h-8 md:h-10 flex items-center justify-center border border-l-0 border-[#d3d3d3] hover:border-[#c6c6c6] rounded-r-3xl"
          >
            <IoIosSearch className="text-[#303030] text-xl" />
          </button>
        </div>
      </form>

      {/** right icons */}
      <div className="flex items-center gap-3 ">
        <div className="flex items-center justify-center cursor-pointer h-10 w-10 rounded-full hover:bg-[#f0f0f0]">
          <RiVideoAddLine className="h-6 w-6" />
        </div>
        <div className="flex items-center justify-center cursor-pointer h-10 w-10 rounded-full hover:bg-[#f0f0f0]">
          <FiBell className="h-6 w-6" />
        </div>
        <div className="flex h-8 w-8 overflow-hidden rounded-full cursor-pointer">
          <img src="https://xsgames.co/randomusers/assets/avatars/male/45.jpg" />
        </div>
      </div>
    </div>
  );
};

export default Header;
