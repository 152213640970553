import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { categories } from "../utils/constants";
import { Context } from "../context/contextApi";
import LeftNavMenuItem from "../components/LeftNavMenuItem";

const LeftNav = () => {
  const {
    selectedCategories,
    setSelectedCategories,
    mobileMenu,
    searchQuery,
    setSearchQuery,
  } = useContext(Context);

  const navigate = useNavigate();

  const clickHandle = (name, type) => {
    switch (type) {
      case "category":
        return setSearchQuery(name);
      case "home":
        return setSearchQuery(name);
      case "menu":
        return false;
      default:
        break;
    }
  };

  return (
    <div className="w-[240px] hidden  lg:block overflow-hidden hover:overflow-y-scroll h-full py-4 md:relative">
      <div className="flex px-5 flex-col">
        {categories?.map((item, index) => {
          return (
            <React.Fragment>
              <LeftNavMenuItem
                key={index}
                text={item.type === "home" ? "Home" : item.name}
                icon={item.icon}
                action={() => {
                  clickHandle(item.name, item.type);
                  navigate("/");
                }}
                className={`${
                  searchQuery === item.name ? " bg-[#f0f0f0] " : ""
                }`}
              />
              {item.divider && <hr className="my-5 border-[#d3d3d3]" />}
            </React.Fragment>
          );
        })}
        <hr className="my-5 border-[#d3d3d3]" />
        <div className="text-opacity-50 text-xs">@ 2023 Google LLC</div>
      </div>
    </div>
  );
};

export default LeftNav;
