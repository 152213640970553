import React, { Component } from "react";
import moment from "moment/moment";

const VideoLength = ({ time }) => {
  const videoLengthSeconds = moment()
    .startOf("day")
    .seconds(time)
    .format("H:mm:ss");

  const handleDurationFormat = (duration) => {
    const myArray = duration.split(":");
    let newDuration;
    if (myArray[0] === "0") {
      newDuration = myArray[1] + ":" + myArray[2];
      return newDuration;
    }
    return duration;
  };

  return (
    <div className="absolute bottom-2 right-2 bg-black py-0.5 px-1 opacity-90 text-white font-bold text-xs rounded-md ">
      {handleDurationFormat(videoLengthSeconds)}
    </div>
  );
};

export default VideoLength;
