import React, { Component } from "react";
import { abbreviateNumber } from "js-abbreviation-number";
import { Link } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import VideoLength from "../shared/VideoLength";

const VideoCard = ({ video }) => {
  return (
    <Link to={`/video/details/${video?.videoId}`} className="mb-5">
      <div className="flex flex-col ">
        <div className="relative  md:rounded-xl overflow-hidden">
          <img
            src={video?.thumbnails?.[0].url}
            alt=""
            className="h-full w-full object-cover"
          />
          {video?.lengthSeconds && <VideoLength time={video?.lengthSeconds} />}
        </div>
        <div className="flex mt-3">
          <div className="flex items-start">
            <div className="flex h-9 w-9 rounded-full overflow-hidden">
              <img
                src={video?.author?.avatar[0]?.url}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div className="flex flex-col ml-3 overflow-hidden">
            <span className="text-[16px] font-semibold line-clamp-2">
              {video?.title}
            </span>
            <span className="text-[14px] mt-1 flex items-center text-[#606060]">
              <span className="line-clamp-1 truncate">
                {video?.author?.title}
              </span>
              {video?.author?.badges[0]?.type === "VERIFIED_CHANNEL" ||
              video?.author?.badges[0]?.type === "OFFICIAL_ARTIST_CHANNEL" ? (
                <BsFillCheckCircleFill className="text-[14px] ml-2 opacity-70" />
              ) : (
                ""
              )}
            </span>
            <div className="flex text-[14px] truncate overflow-hidden text-[#606060]">
              <span>{`${abbreviateNumber(video?.stats?.views, 0)} views`}</span>
              <span className="flex text-[24px] leading-none font-bold relative top-[-9px] mx-1">
                .
              </span>
              <span className="truncate">{video?.publishedTimeText}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default VideoCard;
